import image1 from "../assets/PASTA.png";

const meat = [
    { 
      id: 1, 
      category: 'MEAT', 
      title: "Ramstek", 
      subItems: ["Ramstek", "Biftek", "Mish Pule", 'Biftek', 'Skanderbeg' , 'mish pule'], 
      image: image1, 
      price: "11.99"
    },

    { 
      id: 2, 
      category: 'MEAT',
      title: "biftek", 
      subItems: ["Ramstek", "Biftek", "Mish Pule"], 
      image: image1, 
      price: "11.99"
    },

    { 
      id: 3, 
      category: 'MEAT', 
      title: "TROFTA", 
      subItems: ["Ramstek", "Biftek", "Mish Pule"], 
      image: image1, 
      price: "11.99"
    },

    { 
      id: 4, 
      category: 'MEAT', 
      title: "SKANDERBEG", 
      subItems: ["Ramstek", "Biftek", "Mish Pule"], 
      image: image1, 
      price: "11.99"
    },

  ];

export { meat }  