import React from 'react'
import Menu from '../menu/Menu'

const MenuPage = () => {
  return (
    <div className='w-full '>
        <Menu />
    </div>
  )
}

export default MenuPage